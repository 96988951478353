import React, { useState } from "react";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

const ResendVerificationEmail = () => {
  // const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  // const handleInputChange = (event) => {
  //   setEmail(event.target.value);
  // };

  const { email } = useParams();

  const handleResendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage("");
    axios
      .post("/user/resend-email-verification/", { email: email })
      .then((response) => {
        if (response.status === 200) {
          setMessage("Verification email sent successfully.");
        } else {
          setMessage(
            "Failed to resend verification email. Please try again later."
          );
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setMessage("An error occurred. Please try again later.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="forgotPass">
      <div className="forgotPassFormConatiner">
        <div className="forgotPassBox">
          {message && <p>{message}</p>}
          <Form onSubmit={handleResendEmail}>
            <Form.Label htmlFor="email">Your email:</Form.Label>
            <Form.Control
              type="email"
              id="email"
              value={email}
              placeholder="Enter your email"
              // onChange={handleInputChange}
              required
            />
            <Button
              className="btn--secondary"
              style={{ marginTop: "20px" }}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Sending..." : "Resend Verification Email"}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ResendVerificationEmail;
